
@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Black'), local('Roboto-Black'),
        url('./Roboto-Black.woff2') format('woff2'),
        url('./Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BlackItalic.woff2') format('woff2'),
        url('./Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Bold'), local('Roboto-Bold'),
        url('./Roboto-Bold.woff2') format('woff2'),
        url('./Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BoldItalic.woff2') format('woff2'),
        url('./Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Italic.woff2') format('woff2'),
        url('./Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Light'), local('Roboto-Light'),
        url('./Roboto-Light.woff2') format('woff2'),
        url('./Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-LightItalic.woff2') format('woff2'),
        url('./Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-MediumItalic.woff2') format('woff2'),
        url('./Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Medium'), local('Roboto-Medium'),
        url('./Roboto-Medium.woff2') format('woff2'),
        url('./Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Regular'), local('Roboto-Regular'),
        url('./Roboto-Regular.woff2') format('woff2'),
        url('./Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: 
        local('Roboto Thin'), 
        local('Roboto-Thin'),
        url('./Roboto-Thin.woff2') format('woff2'),
        url('./Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-ThinItalic.woff2') format('woff2'),
        url('./Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-Bold.woff2') format('woff2'),
        url('./RobotoCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('./RobotoCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-Italic.woff2') format('woff2'),
        url('./RobotoCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-Light.woff2') format('woff2'),
        url('./RobotoCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('./RobotoCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./RobotoCondensed-Regular.woff2') format('woff2'),
        url('./RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

