
$breakpoint: 992px;

.Home {

    button {

        cursor: pointer;
        background: none;
        border: 3px solid black;
        border-radius: 15px;
        font-size: 1.3em;
        line-height: 1.3em;

    }

    h1 {

        font-weight: 500;
        font-size: 1.7em;

        color: #000000;

    }

    h3 {

        color:#486880;
        font-weight: 400;

    }


    > section.sep {

        background-color: #486880;
        text-align: center;

        $paddingVertical: 40px;

        padding-top: $paddingVertical;
        padding-bottom: $paddingVertical;

        $marginSide: 60px;

        margin-left: $marginSide;
        margin-right: $marginSide;

        > h1 {

            position: relative;
            display: inline-block;

            margin-top: 0;

            &::after {
                display: block;
                position: absolute;
                content: '';
                bottom: -30%;
                width: 40%;
                left: 30%;
                border-bottom: 1px solid white;
            }

        }

        > h1, > p {
            color: white;
        }

        > p {
            width: 60%;
            margin: auto;
            margin-top: 10px;

            @media (max-width: $breakpoint) {
                width: 90%;
            }

        }

        -webkit-box-shadow: 9px 12px 5px -5px rgba(0,0,0,0.27);
        -moz-box-shadow: 9px 12px 5px -5px rgba(0,0,0,0.27);
        box-shadow: 9px 12px 5px -5px rgba(0,0,0,0.27);

        @media (max-width: $breakpoint) {
            $marginSide: 2px;

            margin-left: $marginSide;
            margin-right: $marginSide;
        }

    }

    /*
    > section:last-of-type {
        margin-bottom: 60px;
    }
    */

    > section.scheme {

        margin-top: 60px;
        margin-bottom: 60px;

        display: flex;
        flex-wrap: wrap;

        //$paddingLateral: 10%;
        $paddingLateral: 18%;

        padding-left: $paddingLateral;
        padding-right: $paddingLateral;

        > div:nth-of-type(1) {
            flex: 1;
            min-width: 350px;

            padding-right: 10%;


            > p {
                

                //font-size: 75%;
                line-height: 33px;

                color: #000000;
                opacity: 0.6;


            }

            > button {

                cursor: pointer;
                margin-top: 30px;
                margin-bottom: 50px;

            }

            @media (max-width: $breakpoint) {

                min-width: unset;
                padding-right: 0%;

            }


        }

        > img {
            width: 560px;
            height: 426px;
            @media (max-width: $breakpoint) {

                width: unset;
                max-width: 90%;
                height: unset;

            }
        }

        @media (max-width: 1600px) {
            $paddingLateral: 12%;
            padding-left: $paddingLateral;
            padding-right: $paddingLateral;
        }


        @media (max-width: $breakpoint) {
            $paddingLateral: 7%;
            padding-left: $paddingLateral;
            padding-right: $paddingLateral;
        }


    }



}