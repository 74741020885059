
@import 'fonts/font-face.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto';

  box-sizing: border-box;

  *:not(img), *:before, *:after { box-sizing: inherit; }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



