
@import "./trig";

$transitionDurationInteger: 1500;
$transitionDuration: $transitionDurationInteger + 0ms;
$angle: -24deg;
$oppositeToAngleSideLength: tan(-$angle) * 100vh;

:export {
  transitionDuration: $transitionDurationInteger;
}

.CountDownAndHomeCastle {

  width: 100%;
  height: 100vh;

  position: relative;
  z-index: 1;
  $width_of_castle: 70%;

  .castle {

    //border: 6px solid yellow;

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;

    height: 100%;
    width: $width_of_castle;

    transition: width $transitionDuration;

    background-image: url(./castle_background.png);

    background-position: right 50% bottom 0%;
    background-repeat: no-repeat;
    background-size: cover;

    /*  --- Overlay --- */

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -3;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.30);

      transition: background-color $transitionDuration;

    }

    /* --- skew separation (as an after element) ---- */
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      top: 0;
      right: 100%;
      width: 10000px;
      height: 100%;
      transform-origin: bottom;
    
    
      -webkit-transform: skewX($angle);
      -moz-transform: skewX($angle);
      -ms-transform: skewX($angle);
      -o-transform: skewX($angle);
      transform: skewX($angle);
    
      transition: transform $transitionDuration;
    
      background-color: #fff;
    
    }

    > .countdown {


      position: absolute;
      width: calc(100% - #{$oppositeToAngleSideLength});
      height: 100%;

      //border: black 5px solid;
      right: 0;

      opacity: 1;

      transition: opacity $transitionDuration;

      display: flex;
      justify-content: center;
      align-items: center;

      > div {

        text-align: center;


        h1 {
          color: White;
          font-weight: 500;
          font-size: 2.4em;

          @media (min-width: 1400px) {
            font-size: 3.4em;
          }

          @media (min-width: 1921px) {
            font-size: 5em;
          }

        }



        > div {
          display: flex;
          justify-content: center;
          //border: black 5px solid;
          > div {

            margin: 20px;

            border: 3px solid rgba(255,255,255,0.3);
            border-radius: 50%;

            $diameter: 190px;

            width: $diameter;
            height: $diameter;


            > h3, span {
              color: white;
            }

            > h3 {
              font-size: 1.5em;
              margin-bottom: 7px;
            }

            > h2 {
              color: white;
            }

            > div:nth-of-type(1) {
              margin-top: 17px;
              font-size: 2.3em;
            }
            > div:nth-of-type(2) {
              font-size: 1.5em;
            }

            @media (orientation: portrait) {

              margin: -14px;
              margin-top: 10px;

            }

          }
        }


        @media (orientation: portrait) {

          margin-bottom: unset;
          margin-right: unset;
          margin-top: 5px;

        }


      }

      @media (orientation: portrait) {

        position: unset;
        width: 100%;
        right: unset;
        align-items: unset;


      }

    }


    @media (orientation: portrait) {

      position: relative;
      width: 100%;
      height: 55%; 
      transition: height $transitionDuration;

      $angle: 8deg;
      
      &::after {
        top: 100%;
        right: 0;
        width: 100%;
        height: 10000px;
        transform-origin: top right;
        -webkit-transform: skew(180deg, $angle);
        -moz-transform: skew(180deg, $angle);
        -ms-transform: skew(180deg, $angle);
        -o-transform: skew(180deg, $angle);
        transform: skew(180deg, $angle);
      }

      
    }


  }

  .countdownSidePanel {

    //border: 3px red solid;

    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    height: 100%;

    //width: calc(100% - #{$width_of_castle} + #{$oppositeToAngleSideLength});
    //width: calc(100% - #{$width_of_castle} + 20%);

    transition: width 0s $transitionDuration;

    > * {
      transition: transform $transitionDuration;
    }

    display: flex;
    flex-wrap: wrap;
  
    align-items: center;

    > div {

      width: 100%;

      padding-left: 40px;

      > h1 {
      
        font-weight: 500;
        font-size: 2.5em;

        color:black;

        @media (orientation: portrait) {
          font-size: 1em;
        }

        @media (min-width: 1400px) {
          font-size: 4em;
        }

        @media (min-width: 1921px) {
          font-size: 6em;
        }
      
      }

      > h2 {


        font-weight: 400;
        font-size: 1.1em;

        @media (orientation: portrait) {
          font-size: 0.8em;
        }

        @media (orientation: portrait), (max-width: 1200px) {
          font-size: 0.9em;
        }

        @media (min-width: 1400px) {
          font-size: 1.2em;
        }

      }

      > div button {

        cursor: pointer;

        margin-top: 20px;

        background: none;
        border: 3px solid black;
        border-radius: 15px;
        font-size: 1.3em;
        line-height: 1.3em;

        width: 50%;

      }

      > .links {

        position: absolute;

        bottom: 20px;
        left: 20px;

        > a:first-of-type {
          margin-right: 20px;
        }


        @media (orientation: portrait) {

          visibility: hidden;

        }


      }


      @media (orientation: portrait) {

        margin-top: 0%;
        width: 100%;
        padding-left: 0;
        text-align: center;

      }

    }

    @media (orientation: portrait) {



      position: initial;
      top: unset;
      left: unset;
      height: unset;
      width: unset;


    }

  }

  &.homepageState {

    .countdownSidePanel {

      > * {
        transform: translate(-1000px);
      }

      .links {
        opacity: 0;
        //visibility: hidden;
      }

    }

    .castle {
    
      width: 100%;

      &::before {
        background-color: rgba(0,0,0,0);
      }

      &::after {
        
        $angle: 0deg;
        
        -webkit-transform: skewX($angle);
        -moz-transform: skewX($angle);
        -ms-transform: skewX($angle);
        -o-transform: skewX($angle);
        transform: skewX($angle);
        
      }

      > .countdown {

        opacity: 0%;

      }

      > .home {

        > div:first-of-type {
          text-align: center;
          > img {
            width: 280px
          }
        }

        > div:nth-of-type(2) {
          text-align: center;
          > h1 {
            margin-top: -11px;
            font-weight: 500;
            font-size: 1.5em;
          }
          > h3 {
            font-weight: 300;
            font-size: 1em;
          }
          > h1, > h3 {
            color: white;
          }


          @media (orientation: portrait) {
            > h1 {
              font-size: 1em;
            }
            > h3 {
              font-size: 0.8em;
            }
          }

        }

        display: flex;
        flex-direction: column;
        height: 100%;

        > div:nth-of-type(3) {
          flex-grow:1;
          text-align: center;
          position: absolute;
          width: 100%;
          bottom: 0;


          margin-bottom: 36px;

          > div button.startGameButton {

            cursor: pointer;

            background-color: #8090D8;
            border-radius: 15px;
            border: 3px white solid;
            color: white;
            font-size: 2em;

            -webkit-box-shadow: 14px 16px 31px -14px rgba(0,0,0,0.53);
            -moz-box-shadow: 14px 16px 31px -14px rgba(0,0,0,0.53);
            box-shadow: 14px 16px 31px -14px rgba(0,0,0,0.53);

          }

          > div:nth-of-type(2) {

            margin-top: 40px;

            $buttonWidth: 70px;

            > button.slideDownButton {
              width: $buttonWidth;
              background-image: url(./slideDown.svg);
              height: 39px;
              background-repeat: no-repeat;
              background-size: contain;
              border: none;
              background-color: transparent;
            }

            @media (orientation: portrait) {
              margin-top: unset;
              position: absolute;
              bottom: 70px;
              left: calc(50% - #{$buttonWidth}/2);
            }

          }

          @media (orientation: portrait) {

            position: unset;
            bottom: unset;
            margin-bottom: unset;
            margin-top: 10px;

          }





        }

      }

      @media (orientation: portrait) {

        height: 100%;

        &::after {

          $angle: 0;

          -webkit-transform: skew(180deg, $angle);
          -moz-transform: skew(180deg, $angle);
          -ms-transform: skew(180deg, $angle);
          -o-transform: skew(180deg, $angle);
          transform: skew(180deg, $angle);

        }


      }
      

    }


  }

}


